import React, { Component } from 'react'

export default class Expertise extends Component {
  render() {
    return (
      <section className="colorlib-services" data-section="services">
        <div className="colorlib-narrow-content">
          <div className="row">
            <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
              <span className="heading-meta">What exactly do I do?</span>
              <h2 className="colorlib-heading">Here is what I can offer for you</h2>
            </div>
          </div>
          <div className="row row-pt-md">

            <div className="col-md-4 text-center animate-box">
              <div className="services color-3">
                <span className="icon">
                  <i className="icon-display2" />
                </span>
                <div className="desc">
                  <h3>Designs &amp; Interfaces</h3>
                  <p>Wireframes of what the client is looking for when it comes to their website.</p>
                </div>
              </div>
            </div>
            
            <div className="col-md-4 text-center animate-box">
              <div className="services color-2">
                <span className="icon">
                  <i className="icon-th-list" />
                </span>
                <div className="desc">
                  <h3>Features &amp; Plugins</h3>
                  <p>Finding the right plugin for the client to get their desired effect with their website.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-center animate-box">
              <div className="services color-4">
                <span className="icon">
                  <i className="icon-phone" />
                </span>
                <div className="desc">
                  <h3>Dedicated Support</h3>
                  <p>Whether it's Web Design or "How do I turn on my internet", I'm always here to help.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-center animate-box">
              <div className="services color-5">
                <span className="icon">
                  <i className="icon-html-five" />
                </span>
                <div className="desc">
                  <h3>Coding &amp; Development</h3>
                  <p>I specialize in front end HTML and CSS with a good foundation of jQuery and PHP as well.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-center animate-box">
              <div className="services color-1">
                <span className="icon">
                  <i className="icon-bulb" />
                </span>
                <div className="desc">
                  <h3>Ideas &amp; Concepts</h3>
                  <p>Meeting with interested clients on what it is they're looking for on their website.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-center animate-box">
              <div className="services color-6">
                <span className="icon">
                  <i className="icon-device-phone" />
                </span>
                <div className="desc">
                  <h3>Responsive Design</h3>
                  <p>I always make sure the websites I build are fluid and responsive with mobile devices leading the way.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}