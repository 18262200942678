import React, { Component } from 'react'
import $ from 'jquery'
import Recaptcha from 'react-recaptcha'
export default class Contact extends Component {
  state = {
    inputEmail: '',
    inputMessage: '',
    inputName: '',
    isCaptchaValid: false,
    isErrorShown: false,
    isFormValid: false
  }

  // Handle visitor's interaction with inputs
  handleInput = event => {
    // Test for input and length of the value
    if (event.target.value.length > 0 && event.target.name !== 'inputEmail') {
      this.setState({
        [event.target.name]: event.target.value
      })
    }

    // If input is for email address validate it with regexp
    if (event.target.name === 'inputEmail') {
      // eslint-disable-next-line
      const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      if (reg.test(String(event.target.value).toLowerCase())) {
        this.setState({
          [event.target.name]: event.target.value
        })
      }
    }
  }

  // Handle visitor's interaction with checkboxes
  handleCheckbox = event => {
    this.setState({
      [event.target.name]: event.target.checked
    })
  }

  // Show message in console when reCaptcha plugin is loaded
  onCaptchaLoad = () => {
    console.log('Captcha loaded')
  }

  // Update state after reCaptcha validates visitor
  onCaptchaVerify = (response) => {
    this.setState({
      isCaptchaValid: true
    })
  }

  handleFormSubmit = event => {
    event.preventDefault()

    // Test
    if (this.state.inputEmail.length > 0 && this.state.inputName.length > 0 && this.state.inputMessage.length > 0 && this.state.isCaptchaValid) {
      this.setState({
        isErrorShown: false,
        isFormValid: true
      })

      // Send the form with AJAX
      $.ajax({
        data: this.state,
        type: 'POST',
        url: '/contact/mailer.php',
        success: function (data) {
          console.info(data)
          $('.contact-form-hide').hide();
          $('.thankyou').show();
        },
        error: function (xhr, status, err) {
          console.error(status, err.toString())
        }
      })

      // Reset state after sending the form
      this.setState({
        inputEmail: '',
        inputMessage: '',
        inputName: '',
        isCaptchaValid: false,
        isErrorShown: false,
        isFormValid: false
      })
    } else {
      // Show error message
      this.setState({
        isErrorShown: true
      })
    }
  }

  render() {
    return (
      <section className="colorlib-contact" data-section="contact">
        <div className="colorlib-narrow-content">
          <div className="row">
            <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
              <span className="heading-meta">Get in Touch</span>
              <h2 className="colorlib-heading">Contact</h2>
              <p className="contact-form-hide">Leave me a submission using the form below or just <a href="mailto:rklitz11@gmail.com">email me!</a></p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-10 col-md-offset-1 col-md-pull-1 animate-box" data-animate-effect="fadeInRight">
                  <form className="contact-form contact-form-hide" action="">
                    <div className="form-group">
                      <input onChange={this.handleInput} type="text" name="inputName" className="form-control" id="inputName" placeholder="Name" required={true} />
                    </div>
                    <div className="form-group">
                      <input onChange={this.handleInput} type="email" name="inputEmail" className="form-control" id="inputEmail" placeholder="Email" required={true} />
                    </div>
                    <div className="form-group">
                      <textarea onChange={this.handleInput} name="inputMessage" className="form-control" id="inputMessage" placeholder="Message" required={true} />
                    </div>
                    <div className="form-group">
                      <Recaptcha
                        onloadCallback={this.onCaptchaLoad}
                        sitekey="6LcKCsEZAAAAAHV-6WieTPo1VWvWXNBcYWOymYWc"
                        render="explicit"
                        verifyCallback={this.onCaptchaVerify}
                      />
                    </div>

                    {this.state.isFormSubmitted && (
                      <div>
                        <p>Thank you for contacting me. I will reply in four days.</p>
                      </div>
                    )}
                    {this.state.isErrorShown && (
                      <div>
                        <p>Please fill out all the fields</p>
                      </div>
                    )}

                    <div className="form-group">
                      <input type="submit" className="btn btn-primary btn-send-message" onClick={this.handleFormSubmit} value="Submit" />
                    </div>
                  </form>
                  <div className="thankyou">
                    <p>Thank you for reaching out to me! I'll get back to you as soon as possible and look forward to working with you.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

}