import React, { Component } from 'react'

export default class Intro extends Component {
    render() {
        return (
          <section id="colorlib-hero" className="js-fullheight" data-section="home">
            <div className="flexslider js-fullheight">
              <ul className="slides">
                <li style={{ backgroundImage: 'url(images/img_bg_1.jpg)' }}>
                  <div className="overlay" />
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                        <div className="slider-text-inner js-fullheight">
                          <div className="desc">
                            <h1>Hey there! <br />I'm Robert</h1>
                            <h2>I'm a Web Designer that can help get your website up and running in no time.</h2>
                            <p><a href="http://rklitz.com/assets/Robert_Klitz_Resume.PDF" className="btn btn-primary btn-learn" target="_blank">View Resume <i className="icon-download4" /></a></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li style={{ backgroundImage: 'url(images/img_bg_2.jpg)' }}>
                  <div className="overlay" />
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                        <div className="slider-text-inner">
                          <div className="desc">
                            <h1>Get In<br />Touch</h1>
                            <h2>Below you'll find a little bit more about me and who I am.</h2>
                            <p><a href="mailto:rklitz11@gmail.com?" className="btn btn-primary btn-learn">Contact Me <i className="icon-briefcase3" /></a></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </section>
        )
    }
}