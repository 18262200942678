import React, { Component } from 'react'

export default class Sidebar extends Component {
  render() {
    return (
      <div>
        <div>
          <a href="#" className="js-colorlib-nav-toggle colorlib-nav-toggle" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar"><i /></a>
          <aside id="colorlib-aside" role="complementary" className="border js-fullheight">
            <div className="text-center">
              <div className="author-img" style={{ backgroundImage: 'url(images/about.jpg)' }} />
              <h1 id="colorlib-logo"><a href="index.html">Robert Klitz</a></h1>
              <span className="position"><a href="#">Web Designer/Developer</a> in South Florida</span>
            </div>
            <nav id="colorlib-main-menu" role="navigation" className="navbar">
              <div id="navbar" className="collapse">
                <ul>
                  <li className="active"><a href="#" data-nav-section="home">Home</a></li>
                  <li><a href="#" data-nav-section="about">About</a></li>
                  <li><a href="#" data-nav-section="services">Services</a></li>
                  <li><a href="#" data-nav-section="skills">Skills</a></li>
                  <li><a href="#" data-nav-section="education">Education</a></li>
                  <li><a href="#" data-nav-section="experience">Experience</a></li>
                  <li><a href="#" data-nav-section="contact">Contact</a></li>
                </ul>
              </div>
            </nav>
            <div className="colorlib-footer">
              <p>This template was created with <i className="icon-beer" aria-hidden="true" /> and <i className="icon-coffee" aria-hidden="true" />. Big thank you to <a href="https://colorlib.com" target="_blank">Colorlib</a> for the inspiration</p>
              <ul>
                <li><a href="https://www.facebook.com/robert.klitz"><i className="icon-facebook2" /></a></li>
                <li><a href="https://twitter.com/Klitz_"><i className="icon-twitter2" /></a></li>
                <li><a href="https://www.instagram.com/rklitz_/"><i className="icon-instagram" /></a></li>
                <li><a href="https://www.linkedin.com/in/robert-klitz-b0163080/"><i className="icon-linkedin2" /></a></li>
                <li><a href="mailto:rklitz11@gmail.com"><i className="icon-mail5" /></a></li>
              </ul>
            </div>
          </aside>
        </div>
      </div>
    )
  }
}