import React, { Component } from 'react'

export default class Jobs extends Component {
  render() {
    return (
      <section className="colorlib-experience" data-section="experience">
        <div className="colorlib-narrow-content">
          <div className="row">
            <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
              <span className="heading-meta">I've laid out my work experience below to only include jobs related to my current field</span>
              <h2 className="colorlib-heading animate-box">Work Experience</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="timeline-centered">
                <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                  <div className="timeline-entry-inner">
                    <div className="timeline-icon color-1">
                      <i className="icon-pen2" />
                    </div>
                    <div className="timeline-label">
                      <h2><a href="#">Web Designer @ AMSI/AAG</a> <span>2017 - Present</span></h2>
                      <p>Collaborated with a team of designers to create sales campaigns each month for the dealerships that our company owns. These ranged from specials, to display ads, to email blasts. I was in charge of creating responsive email templates and worked with the Email Department to create these. I was also tasked with numerous other projects throughout the company that allowed me to work with all of the varying departments.</p>
                    </div>
                  </div>
                </article>
                <article className="timeline-entry animate-box" data-animate-effect="fadeInRight">
                  <div className="timeline-entry-inner">
                    <div className="timeline-icon color-2">
                      <i className="icon-pen2" />
                    </div>
                    <div className="timeline-label">
                      <h2><a href="#">Web Designer @ Island Company</a> <span>2014 - 2017</span></h2>
                      <p>Working with a team of developers and graphic designers, I was in charge of creating all of the front-end layouts on the web as well as making them responsive. We also developed an application using heavy amounts of PHP and jQuery libraries to manipulate Magento to make it easier to update and maintain the front end of the site.</p>
                    </div>
                  </div>
                </article>
                <article className="timeline-entry begin animate-box" data-animate-effect="fadeInBottom">
                  <div className="timeline-entry-inner">
                    <div className="timeline-icon color-none">
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}