import React, { Component } from 'react'

export default class About extends Component {
  render() {
    return (
      <section className="colorlib-about" data-section="about">
        <div className="colorlib-narrow-content">
          <div className="row">
            <div className="col-md-12">
              <div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
                <div className="col-md-12">
                  <div className="about-desc">
                    <span className="heading-meta">About Me</span>
                    <h2 className="colorlib-heading">Who is Robert Klitz and how can he help me?</h2>
                    <p><strong>Robert Klitz lives down in South Florida and has been working in the Web Design field for 6 years professionally and loves getting the client exactly what they want.</strong></p> 
                    <p>But talking in the third person is quite strange so here's who I am and how I can help YOU get what YOU are looking for. I specialize in creating the website of your dreams and thanks to my years of experience professionally, I've been able to accomplish this for many different clients both in my professional career as well as my freelance ventures. With my time in the field, I've gotten the chance to work with CMS platforms such as WordPress and Magento and many different business sectors including retail, automotive, and alcohol as well as numerous freelance clients ranging from landscaping to dive operators. So hopefully together we can create the website of your dreams and I look forward to hearing from you. </p>
                    <p>And finally for those who care, some quick facts about me:</p>
                    <ul>
                      <li>Age | 29</li>
                      <li>Height | 5'11 (truly blessed)</li>
                      <li>Hometown | Coral Springs, Florida</li>
                      <li>Fear | Very tight spaces</li>
                      <li>Hobbies | Fishing, Disc Golf, Biking, Gaming, Camping, Boating</li>
                      <li>Sports Teams | Carolina Panthers, Miami Heat</li>
                      <li>Pets | Tika (dog) Toulouse (cat)</li>
                      <li>Married | To my beautiful wife</li>
                      <li>Favorite Beer | Yes</li>
                    </ul>
                    <p>For those of you who prefer to read a picture book, here's my Instagram feed to give you a better idea of who I am</p>
                    <iframe src="https://cdn.lightwidget.com/widgets/4e37930e47825a75bbab05aa44e9dc9e.html" scrolling="no" allowtransparency="true" className="lightwidget-widget"></iframe>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 animate-box" data-animate-effect="fadeInRight">
                  <div className="services color-2">
                    <span className="icon2"><i className="icon-globe-outline" /></span>
                    <h3>Web Design</h3>
                    <p>I have over 14 years of experience with Web Design mainly dealing with HTML and CSS. As well as 8+ years with Javascript, jQuery, and numerous other libraries.</p>
                  </div>
                </div>
                <div className="col-md-6 animate-box" data-animate-effect="fadeInTop">
                  <div className="services color-3">
                    <span className="icon2"><i className="icon-data" /></span>
                    <h3>Web Developer</h3>
                    <p>With my former employer and current employer I've had the ability to learn more about development and have gotten familiar with PHP over the years as a result</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 animate-box" data-animate-effect="fadeInLeft">
                  <div className="services color-1">
                    <span className="icon2"><i className="icon-bulb" /></span>
                    <h3>Graphic Design</h3>
                    <p>I have close to 14 years of experience with Photoshop along with other Adobe Programs</p>
                  </div>
                </div>
                <div className="col-md-6 animate-box" data-animate-effect="fadeInBottom">
                  <div className="services color-4">
                    <span className="icon2"><i className="icon-phone3" /></span>
                    <h3>Team Based Environments</h3>
                    <p>I thrive in team based environments but also have been able to succeed with Freelance work</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}