import React, { Component } from 'react';
import './App.css';
import About from './components/about'
import Contact from './components/contact'
import Counter from './components/counter'
import Education from './components/education'
import Expertise from './components/expertise'
import Intro from './components/intro'
import Jobs from './components/jobs'
import Sidebar from './components/sidebar'
import Skills from './components/skills'


class App extends Component {
    render() {
        return (
            <div id="colorlib-page">
                <div id="container-wrap">
                    <Sidebar></Sidebar>
                    <div id="colorlib-main">
                        <Intro></Intro>
                        <About></About>
                        <Expertise></Expertise>
                        <Counter></Counter>
                        <Skills></Skills>
                        <Education></Education>
                        <Jobs></Jobs>
                        <Contact></Contact>
                    </div>
                </div>
            </div>    
        )
    }
}

export default App;
