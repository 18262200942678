import React, { Component } from 'react'

export default class Counter extends Component {
  render() {
    return (
      <div id="colorlib-counter" className="colorlib-counters" style={{ backgroundImage: 'url(images/cover_bg_1.jpg)' }} data-stellar-background-ratio="0.5">
        <div className="overlay" />
        <div className="colorlib-narrow-content">
          <div className="row">
          </div>
          <div className="row">
            <div className="col-md-3 text-center animate-box">
              <span className="colorlib-counter js-counter" data-from={0} data-to={573} data-speed={5000} data-refresh-interval={50} />
              <span className="colorlib-counter-label">Cups of Coffee</span>
            </div>
            <div className="col-md-3 text-center animate-box">
              <span className="colorlib-counter js-counter" data-from={0} data-to={47} data-speed={5000} data-refresh-interval={50} />
              <span className="colorlib-counter-label">Websites Completed</span>
            </div>
            <div className="col-md-3 text-center animate-box">
              <span className="colorlib-counter js-counter" data-from={0} data-to={786} data-speed={5000} data-refresh-interval={50} />
              <span className="colorlib-counter-label">Beers Consumed</span>
            </div>
            <div className="col-md-3 text-center animate-box">
              <span className="colorlib-counter js-counter" data-from={0} data-to={5} data-speed={5000} data-refresh-interval={50} />
              <span className="colorlib-counter-label">Freelance Clients</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}